<template>
    <div class="ml-2">
        <div id="notfound">
            <div class="notfound">
				<div class="container-fluid p-5">
					<img src="@/assets/landing-page/icons/mobile-nf.svg" alt="Mobile" class="img-fluid">
					<h2 class="mt-3 mb-4">Currently, SOCA does not support mobile browsers</h2>
					<p>Please open Soca Creator Studio using <b>Desktop</b> or <b>Laptop</b> for a better experience</p>
					<div class="my-5">
						<button class="btn btn-purple-3 font-weight-bold btn-md mt-4 mb-5 mx-auto my-md-5" @click="go">Back to Home<i class="bi bi-arrow-right ml-2 ic-arrow"></i></button>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Report",
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
		go(){
			localStorage.clear();
			sessionStorage.clear()
			this.$router.push({ path : '/' })
		}
    },
    middleware: 'router-auth'
};
</script>
<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

body {
	padding: 0;
	margin: 0
}

#notfound {
	position: relative;
	height: 100vh
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.notfound {
	max-width: 920px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px
}

.notfound .notfound-404 {
	position: absolute;
	height: 100px;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: -1
}

.notfound .notfound-404 h1 {
	font-family: maven pro, sans-serif;
	color: #ececec;
	font-weight: 900;
	font-size: 276px;
	margin: 0;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.notfound h2 {
	font-size: 24px;
	color: #fff;
	font-weight: 600;
	line-height: 32.78px;
	margin: 0
}

.notfound p {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	margin-top: 15px
}

.notfound a {
	font-size: 14px;
	text-decoration: none;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: .2s all;
	transition: .2s all
}

.notfound a:hover {
	background-color: #fff;
	border-color: #189cf0;
	color: #189cf0
}

.btn-purple-3{
    display: inline-flex;
    align-items: center;
	justify-content: center;
	width: 163px;
    height: 42px;
    cursor: pointer;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    /* background-color: rgb(71 63 244/var(--tw-bg-opacity)); */
    background-color: #6D75F6;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-shadow: 0px 4px 9px rgba(0,0,0,.25);
    --tw-shadow-colored: 0px 4px 9px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    position: relative;
    z-index: 1;
}

.ic-arrow {
    position: absolute;
    transform: translateX(0px);
    opacity: 0;
    transition: transform 0.3s ease-in-out;
}

.btn-purple-3:hover > .ic-arrow{
    transform: translateX(60px);
    opacity: 1;
}


@media only screen and (max-width:480px) {
	.notfound .notfound-404 h1 {
		font-size: 162px
	}
	.notfound h2 {
		font-size: 24px
	}
}
</style>